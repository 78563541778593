import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: useRuntimeConfig().public.sentryDSN,
  environment: useRuntimeConfig().public.environment,
  integrations: [
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: true,
      maskAllText: false,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.2,
  ignoreErrors: ["Failed to execute 'insertBefore' on 'Node':", "The object can not be found here.", "leaflet", "Map container", "Node.insertBefore", "getNearObject", "removeObject"]
});
